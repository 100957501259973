.MobileSearchh {
  .SearchModal {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    width: 100vw !important;
    min-height: 100vh !important;
    background-color: #f2f3f7 !important;

    .searchBar {
      height: 48px;

      .ant-input-group-wrapper {
        span {
          border: none !important;
        }

        input {
          border: none !important;
        }

        .ant-input-group-addon {
          button {
            border: none !important;

            svg {
              font-size: 25px !important;
            }
          }
        }

        .ant-input-suffix {
          .ant-input-clear-icon {
            svg {
              color: white !important;
              font-size: 16px !important;
              background: black !important;
              border-radius: 25px !important;
            }
          }
        }
      }
    }
  }
}

.MobileSearchModal {
  .ant-modal {
    .ant-modal-content {
      top: 0;
      left: 0;
      position: fixed;
      padding: 0 !important;
      height: 100vh !important;
      width: 100vw !important;

      .ant-modal-close-icon {
        display: none !important;
      }

      .ant-modal-header {
        background-color: #f2f3f7 !important;

        .ant-modal-title {
          .searchBar {
            height: 48px;

            .anticon-left {
              font-size: 21px !important;
            }

            .ant-input-group-wrapper {
              span {
                border: none !important;
              }

              input {
                border: none !important;
                // font-size: 14px !important;
                font-size: 18px !important;
              }

              .ant-input-group-addon {
                button {
                  border: none !important;

                  svg {
                    font-size: 25px !important;
                    color: black !important;
                  }
                }
              }

              .ant-input-suffix {
                .ant-input-clear-icon {
                  svg {
                    color: white !important;
                    font-size: 16px !important;
                    background: black !important;
                    border-radius: 25px !important;
                  }
                }
              }
            }
          }
        }
      }

      .ant-modal-body {
        padding: 10px !important;
        min-height: 100vh !important;
        background-color: #f2f3f7 !important;
      }

      .ant-modal-footer {
      }
    }
  }
}

.MobileSearch {
  // min-height: 100vh !important;
  background-color: #f2f3f7 !important;

  .searchBar {
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    height: 50px;
    z-index: 10;
    position: fixed;
    width: 100vw !important;
    background: #ffffff 0% 0% no-repeat padding-box;
  }

  .searchInput {
    border: none !important;
    box-shadow: none !important;
    padding: 0px 10px !important;
  }

  .tag {
    opacity: 1;
    height: 27px;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    background: #ffffff 0% 0% no-repeat padding-box;
    background: unset;
    border: unset;
    padding: 0;
    text-decoration: underline;
  }

  .ant-input::placeholder-shown {
    color: black;
    opacity: 1;
    /* Firefox */
  }

  .ant-input::placeholder {
    color: black !important;
    font-size: 14px !important;
    opacity: 1;
    /* Firefox */
  }

  .ExclamationCircle {
    svg {
      font-size: 21px !important;
      color: #dc3545 !important;
    }
  }

  .product_card {
    .ant-card-body {
      .ant-card-meta {
        .ant-card-meta-detail {
          .ant-card-meta-title {
            span {
              // font-size: 10px !important;
            }
          }

          .ant-card-meta-description {
            span {
              font-size: 12px !important;
            }

            del {
              font-size: 10px !important;
            }

            .mobile_discount_tag {
              span {
                font-size: 10px !important;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-header-spinner-div {
  height: auto;
  padding-top: 100px;
}

.mobile-search-product-container {
  padding: 15px 15px 0px 15px;
}

body .search-load-more-button-mobile {
  color: black;
  background: transparent;
  border: 1px solid black;
  box-shadow: none;
  margin-bottom: 100px;
}

body .search-load-more-button-mobile:hover {
  color: #f2f3f7 !important;
  background: black !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

body .search-menu-brand-list-mobile {
  height: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-top: 16px;
  padding-bottom: 15px;
}

body .search-menu-single-brand-mobile-div {
  width: 33vw;
  padding: 10px 15px;
  border-radius: 50px;
  border: 1px solid #c8c8c8;
  margin-right: 10px;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: #fff;
}

body .search-menu-single-brand-logo-image {
  width: 80px;
  height: 30px;
  object-fit: cover;
}

// Mz css
.mobile-collection-lists {
  row-gap: 2px;
}
